
import React, {Component, useEffect, useState, useRef} from 'react';
import './purchaseprint.css';
import { Container, Row, Col, Table, Button } from "react-bootstrap";
import { useHistory, useParams } from "react-router-dom";


function Purchaseprint() {

    const {id} = useParams();
    const [details, setDetails] = useState([])
    const [data, setData] = useState({})
    
    useEffect(() => {
        getData()
    },[])
    
    const getData = () => {
        var data = {
            "purchase_id": id
        }
    
        fetch(global.url + "purchasePrint" ,{
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        })
        .then((response)=> response.json())
        .then((responseJson)=>{
              setDetails(responseJson.data.details)
              setData(responseJson.data)
        })
    }
    
    const print = () => {
        window.print()
    }

  return (
    <div>
         <Container fluid >
                <Row >
                    <Col xs={12} style={{marginTop: 20}}> 
                    <Button onClick={print} variant="primary">Print</Button>
                        <div className="invoice-bx printing" >
                            <div className="invoice-insd">
                                <p style={{textAlign: 'center', fontSize: 18, fontWeight: 'bold'}}>{global.proname}</p>
                                <p style={{textAlign: 'center', fontSize: 14, fontWeight: 'bold'}}>Purchase Invoice</p>
                                <div>
                                    <Table bordered className='invoicetable'>
                                        <tbody>
                                            <tr>
                                                <th colSpan={4} rowSpan={2}><p style={{fontSize: 12, fontWeight: 'bold', marginBottom: 'unset'}}>إحضار شعار الشركة وعنوانها< br />Fetch Company Logo and Address</p> </th>
                                                <th><p style={{fontSize: 12, fontWeight: 'bold', marginBottom: 'unset'}}>:رقما لقسيمة<br/>Voucher No:{data.purchase_invoice}</p></th>
                                            <th><p style={{fontSize: 12, fontWeight: 'bold', marginBottom: 'unset'}}>تاريخ<br/>Date:{data.purchase_date}</p></th>
                                            </tr>
                                            <tr>  
                                            <th><p style={{fontSize: 12, fontWeight: 'bold', marginBottom: 'unset'}}>فاتورة المورد<br/>Supplier Inv:{data.purchase_supplier_invoice}</p></th>
                                            <th><p style={{fontSize: 12, fontWeight: 'bold', marginBottom: 'unset'}}>تاريخ الفاتورة<br/>Inv Date:{data.purchase_supplier_date}</p> </th>  
                                            </tr>
                                            <tr>
                                                <th colSpan={4} rowSpan={2}><p style={{fontSize: 12, fontWeight: 'bold', marginBottom: 'unset'}}>(إحضار العنوان)المرسل إليه السفينة<br />Consignee Ship To(Fetch Address)</p></th>
                                                <th><p style={{fontSize: 12, fontWeight: 'bold', marginBottom: 'unset'}}> إيفاد من خلال<br/>Dispatch Through:Qatar</p></th>
                                                <th><p style={{fontSize: 12, fontWeight: 'bold', marginBottom: 'unset'}}> شروط التوصيل<br/>Terms of Delivery:Qatar</p> </th>
                                            </tr>
                                            <tr> 
                                               <th><p style={{fontSize: 12, fontWeight: 'bold', marginBottom: 'unset'}}>طريقة / مدة الدفع<br/>Mode/Term of Payment:{data.mode_of_payment}</p></th>
                                               <th><p style={{fontSize: 12, fontWeight: 'bold', marginBottom: 'unset'}}>مرجع آخر<br/>Other Reference:{data.other_references}</p></th>
                                                
                                            </tr>
                                            <tr>
                                                <th  colSpan={4}><p style={{fontSize: 12, fontWeight: 'bold', marginBottom: 'unset'}}>تفاصيل المورد<br/>Supplier Details</p></th>
                                                <th  colSpan={4}><p style={{fontSize: 12, fontWeight: 'bold', marginBottom: 'unset'}}>{data.supplier_name}<br/>{data.supplier_address1}</p></th>  
                                               
                                           </tr>
                                          

                                        <tr>
                                            <th><p style={{fontSize: 12, fontWeight: 'bold', marginBottom: 'unset'}}>عدد<br/>SLNo</p></th>
                                            <th><p style={{fontSize: 12, fontWeight: 'bold', marginBottom: 'unset'}}>(اللون-بحجم)-وصف<br/>Description-(Color, Size)</p></th>
                                            <th><p style={{fontSize: 12, fontWeight: 'bold', marginBottom: 'unset'}}>الشفرة<br/>Code</p></th>
                                            <th><p style={{fontSize: 12, fontWeight: 'bold', marginBottom: 'unset'}}>كمية<br/>Qty</p></th>
                                            <th><p style={{fontSize: 12, fontWeight: 'bold', marginBottom: 'unset'}}>جائزةالوحدة<br/>Unit Price</p></th>
                                            <th><p style={{fontSize: 12, fontWeight: 'bold', marginBottom: 'unset'}}>مجموع<br/>Total</p></th>
                                        </tr>

                                    {details.map((row, index) => (
                                        <tr>
                                            <td><p style={{fontSize: 12}}>{index+1}</p></td>
                                            <td><p style={{fontSize: 12}}>{row.productname}-({row.color} - {row.size})</p></td>
                                            <td><p style={{fontSize: 12}}>{row.code}</p></td>
                                            <td><p style={{fontSize: 12}}>{row.qty}</p></td>
                                            <td><p style={{fontSize: 12}}>{row.unit}</p></td>
                                            <td><p style={{fontSize: 12}}>{row.pqd_total_amount}</p></td>
                                        </tr>
                                    ))}

                                           
                                        <tr>
                                            <th colSpan={4} rowSpan={2}><p style={{fontSize: 12, fontWeight: 'bold', marginBottom: 'unset'}}>:المبلغ بالكلمات<br />Amount in Words: {data.total_in_words}</p> </th>
                                            <th><p style={{fontSize: 12, fontWeight: 'bold', marginBottom: 'unset'}}>المجموع الفرعي<br/>Sub Total</p></th>
                                            <th><p style={{fontSize: 12, fontWeight: 'bold', marginBottom: 'unset'}}>{data.subtotal}</p></th>
                                        </tr> 
                                        <tr>    
                                            <th><p style={{fontSize: 12, fontWeight: 'bold', marginBottom: 'unset'}}>خصم<br/>Discount</p></th>
                                            <th><p style={{fontSize: 12, fontWeight: 'bold', marginBottom: 'unset'}}>{data.purchase_discount}</p></th>
                                        </tr>   

                                        <tr>
                                            <th colSpan={4} rowSpan={3}><p style={{fontSize: 12, fontWeight: 'bold', marginBottom: 'unset'}}>المفوض بالتوقيع<br />Authorised Signatory</p> </th>
                                            <th><p style={{fontSize: 12, fontWeight: 'bold', marginBottom: 'unset'}}>تكلفة التحميل<br/>Loading Cost</p></th>
                                            <th><p style={{fontSize: 12, fontWeight: 'bold', marginBottom: 'unset'}}>{data.landingcost}</p></th>
                                        </tr> 
                                        <tr>    
                                            <th><p style={{fontSize: 12, fontWeight: 'bold', marginBottom: 'unset'}}>رسوم التوصيل الإضافية<br/>Extra Delivery Charges</p></th>
                                            <th><p style={{fontSize: 12, fontWeight: 'bold', marginBottom: 'unset'}}>{data.othercharges}</p></th>
                                        </tr>  
                                        <tr>    
                                            <th><p style={{fontSize: 12, fontWeight: 'bold', marginBottom: 'unset'}}>المبلغ الإجمالي<br/>Total Amount</p></th>
                                            <th><p style={{fontSize: 12, fontWeight: 'bold', marginBottom: 'unset'}}>{data.total}</p></th>
                                        </tr>   

                                        </tbody>   
                                    </Table>
                                </div>
                                
                                
                            </div>
                        </div>
                    </Col>
                    </Row>
            </Container>
    </div>
  )
}

export default Purchaseprint
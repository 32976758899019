import React, {useState, useEffect} from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import * as FaIcons from "react-icons/fa";
import * as RiIcons from "react-icons/ri";
import { Modal , Row, Col, Button } from "react-bootstrap";
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
// import Button from '@mui/material/Button';
import Map from '../google-map/map';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import Loader from '../loader/loader'
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';


const columns = [
    // { id: '1', label: 'Sl No.', minWidth: 50,span: false },
    { id: '1', label: 'Date', minWidth: 50,span: false },
    { id: '2', label: 'User Name', minWidth: 50,span: false },
    { id: '3', label: 'Invoice No.', minWidth: 50,span: false },
    { id: '3', label: 'Barcode', minWidth: 50,span: false },
    { id: '4', label: 'Product Name', minWidth: 50,span: false },
    { id: '5', label: 'Colour', minWidth: 50,span: false },
    { id: '5', label: 'Size', minWidth: 50,span: false },
    { id: '5', label: 'Ref No.', minWidth: 50,span: false },
    { id: '8', label: 'Product In', minWidth: 50,span: false },
    { id: '9', label: 'Product Out', minWidth: 50,span: false },
    { id: '10', label: 'Voucher Type', minWidth: 50,span: false },
    { id: '10', label: 'Voucher Number', minWidth: 50,span: false },
    { id: '10', label: 'Cost', minWidth: 50,span: false },
    { id: '11', label: 'Total Amount', minWidth: 50,span: false },
  ];
  
  function createData(name, code, population, size) {
    const density = population / size;
    return { name, code, population, size, density };
  }

function DigitalWarehouseReport(props) {
  const [usercompanyid, setUsercompanyid] = useState("")
  const [userbranchid, setUserbranchid] = useState("")
  const [usertype, setUsertype] = useState("")
  const [userId, setUserid] = useState("")
  const [date1, setDate1] = React.useState(new Date);
  const [date2, setDate2] = React.useState(new Date);
  const [invoice, setInvoice] = useState("");
  const [refcode, setRefcode] = useState("");
  const [barcode, setBarcode] = useState("");
    const [toggle,  setToggle] = useState(false)
  const [modal, setModal] = useState(false);
  const [age, setAge] = React.useState(false);
  const [input, setInput] = useState(0);
  const [source, setSource] = React.useState([]);
  const [tname, setTname] = React.useState([])

  const [loader, setLoader] = React.useState(false);
  const [routeId, setRouteid] = React.useState("");
  const [routename, setRoutename] = React.useState("");
  const [description, setDescription] = React.useState("");
  const [blocked, setBlocked] = React.useState(0);
  const [batchcode, setBatchcode] = React.useState("");
  const [dateFrom, setDatefrom] = React.useState(new Date)
  const [dateTo, setDateTo] = React.useState(new Date)

  function setData(childData) {
    setToggle(childData)
  }

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChange = (event) => {
    setAge(event.target.value);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const checkChange = (e) => {
    let isChecked = e.target.checked;
    if(isChecked == true) {
      setBlocked(1)
    }
    else {
      setBlocked(0)
    }
    // do whatever you want with isChecked value
  }

  useEffect(() => {
      getData()
    }, [input]);

    const getData = () => {
        var userdata = JSON.parse(localStorage.getItem("user"))
        var branch = JSON.parse(localStorage.getItem("branch"))
        var company = JSON.parse(localStorage.getItem("company"))
        setUsercompanyid(company)
        setLoader(true)
        var data = {
            "companyid": company,
            "page" : input*10,
            "limit": 10,
            "type": userdata.user_type,
            "branchid" : branch
        }
        fetch(global.url + "displayDigitalWarehouseDetail", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data)
          })
          .then((response)=> response.json())
          .then((responseJson)=>{
              console.log("response detailss stock", responseJson)
              setLoader(false)
              if(responseJson.error == false){
                setSource(responseJson.data)
              }
          })
          .catch((error)=>{
              console.log(error)
          })
    }
      const decrement = () => {
        setInput(prevCount => prevCount - 1);
        
      }

     const increment = () => {
        setInput(prevCount => prevCount + 1);
      
      }

    const filter = () => {
      setAge(true)
        var userdata = JSON.parse(localStorage.getItem("user"))
        var branch = JSON.parse(localStorage.getItem("branch"))
        var company = JSON.parse(localStorage.getItem("company"))
        setUsercompanyid(company)
        setUserbranchid(branch)
        setUsertype(userdata.user_type)
        var data = {
            "companyid": company,
            "refcode": refcode,
            "invoice" : invoice,
            "barcode": barcode,
            "type": userdata.user_type,
            "branchid" : branch
          }
          console.log('datasj177', data)
          fetch(global.url + "displayDigitalWarehouseDetail", {
              method: "POST",
              headers: {
                  'Content-Type': 'application/json',
              },
              body: JSON.stringify(data),
            })
            .then((response)=> response.json())
            .then((responseJson)=>{
                console.log("warehouse response", responseJson)
                setSource(responseJson.data)
            })
            .catch((error)=>{
                console.log(error)
            })
      }

      const filterDate = () => {
        setAge(true)
        var userdata = JSON.parse(localStorage.getItem("user"))
        var branch = JSON.parse(localStorage.getItem("branch"))
        var company = JSON.parse(localStorage.getItem("company"))
        setUsercompanyid(company)
        setUserbranchid(branch)
        setUsertype(userdata.user_type)
        var data = {
            "companyid": company,
            "date1" : date1.toISOString().split('T')[0],
            "date2" : date2.toISOString().split('T')[0],
            "type": userdata.user_type,
            "branchid" : branch
          }
          console.log('datasj177', data)
          fetch(global.url + "displayDigitalWarehouseDetail", {
              method: "POST",
              headers: {
                  'Content-Type': 'application/json',
              },
              body: JSON.stringify(data),
            })
            .then((response)=> response.json())
            .then((responseJson)=>{
                console.log("warehouse response", responseJson)
                setSource(responseJson.data)
            })
            .catch((error)=>{
                console.log(error)
            })
      }


    return(
        <div className="main-content">
          
        <Paper sx={{ width: '100%', overflow: 'hidden' }}>
            <div style={{margin: 20, flexDirection: 'row', justifyContent: 'space-between', display: 'flex'}}>
               <p style={{fontSize:25,  marginBottom: 0, fontWeight: 'bold'}}>Replaced Items Report</p>
            </div>

            <Row style={{margin: 20}}>
                <Col sm={12} >
                    <Row>
                        <Col sm={3} style={{marginTop:30}}>
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <DatePicker
                                    label="From"
                                    value={date1}
                                    onChange={(newValue) => {
                                        setDate1(newValue);
                                    }}
                                    renderInput={(params) => <TextField {...params} />}
                                />
                            </LocalizationProvider>
                        </Col>
                        <Col sm={3} style={{marginTop:30}}>
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <DatePicker
                                    label="To"
                                    value={date2}
                                    onChange={(newValue) => {
                                        setDate2(newValue);
                                    }}
                                    renderInput={(params) => <TextField {...params} />}
                                />
                            </LocalizationProvider>
                        </Col>
                        <Col xs={12} md={3} style={{marginTop: 25}}>
                            <Button onClick={filterDate} style={{width: '50%'}} variant="primary">Filter</Button>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={3} style={{marginTop:30}}>
                            <FormControl sx={{width: '100%' }}>
                                <TextField onChange={(e) => setRefcode(e.target.value)} placeholder="Ref Code" value={refcode} id="outlined-basic" variant="outlined"  size="small"/>
                            </FormControl>
                        </Col>
                        <Col sm={3} style={{marginTop:30}}>
                            <FormControl sx={{width: '100%' }}>
                                <TextField onChange={(e) => setInvoice(e.target.value)} placeholder="Invoice" value={invoice} id="outlined-basic" variant="outlined"  size="small"/>
                            </FormControl>
                        </Col>
                        <Col sm={3} style={{marginTop:30}}>
                            <FormControl sx={{width: '100%' }}>
                                <TextField onChange={(e) => setBarcode(e.target.value)} placeholder="Barcode" value={barcode} id="outlined-basic" variant="outlined"  size="small"/>
                            </FormControl>
                        </Col>
                        <Col xs={12} md={3} style={{marginTop: 25}}>
                            <Button onClick={filter} style={{width: '50%'}} variant="primary">Filter</Button>
                        </Col>
                    </Row>

                </Col>
            </Row> 
            
            <TableContainer sx={{ maxHeight: 440, marginTop: 5 }}>
            {loader ? <Loader /> :
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    {columns.map((column) => (
                      <TableCell
                        key={column.id}
                        align={column.align}
                        style={{ minWidth: column.minWidth, color: '#777', fontWeight: 'bold', fontSize: 16 }}
                        colSpan={column.span == true ? 2: 1}
                      >
                        {column.label}
                        
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                
                <TableBody>
                  {
                  source
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, index) => {
                      return (
                        <TableRow style={{cursor: 'pointer'}} tabIndex={-1} key={row.code}>
                              {/* <TableCell style={{fontWeight: 'bold'}}>
                                  {index+1}
                              </TableCell> */}
                              <TableCell style={{fontWeight: 'bold'}}>
                                  {row.date}
                              </TableCell>
                              <TableCell style={{fontWeight: 'bold'}}>
                                  {row.sdw_userid}
                              </TableCell>
                              <TableCell style={{fontWeight: 'bold'}}>
                                  {row.sdw_invoice}
                              </TableCell>
                              <TableCell style={{fontWeight: 'bold'}}>
                                  {row.sdw_barcode}
                              </TableCell>
                              <TableCell style={{fontWeight: 'bold'}}>
                                  {row.sdw_product_name}
                              </TableCell>
                              <TableCell style={{fontWeight: 'bold'}}>
                                  {row.sdw_product_color}
                              </TableCell>
                              <TableCell style={{fontWeight: 'bold'}}>
                                  {row.sdw_product_size}
                              </TableCell>
                              <TableCell style={{fontWeight: 'bold'}}>
                                  {row.sdw_product_refcode}
                              </TableCell>
                              <TableCell style={{fontWeight: 'bold'}}>
                                  {row.product_in}
                              </TableCell>
                              <TableCell style={{fontWeight: 'bold'}}>
                                  {row.product_out}
                              </TableCell>
                              <TableCell style={{fontWeight: 'bold'}}>
                                  {row.voucher_type}
                              </TableCell>
                              <TableCell style={{fontWeight: 'bold'}}>
                                  {row.voucher_no}
                              </TableCell>
                              <TableCell style={{fontWeight: 'bold'}}>
                                  {row.cost}
                              </TableCell>
                              <TableCell style={{fontWeight: 'bold'}}>
                                  {row.total_amount}
                              </TableCell>
                              
                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>
  }
            </TableContainer>
            {/* <TablePagination
              rowsPerPageOptions={[10, 25, 100]}
              component="div"
              count={source.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            /> */}
          </Paper> 
          <Modal
            size="xl"
            show={modal}
            onHide={() => setModal(false)}
            aria-labelledby="example-modal-sizes-title-lg"
            >
            <Modal.Header closeButton>
                <Modal.Title id="example-modal-sizes-title-lg">
                    Detailed Stock
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
            <Table responsive bordered>
              <thead>
                <tr>
                  <th>Voucher Date</th>
                  <th>Voucher No</th>
                  <th>Voucher Type</th>
                  <th>Code</th>
                  <th>Product Name</th>
                  <th>Colour</th>
                  <th>Size</th> 
                  <th>Unit</th>
                  <th>Opening</th>
                  <th>In</th>
                  <th>Out</th>
                  <th>Cost</th>
                  <th>Total</th>
                </tr>
              </thead>
              <tbody>
                  {tname.map((row, index) => (
                    <tr style={{marginTop: 20}}>
                      <td style={{fontSize: 13}}><span style={{fontSize: 13, marginTop: 20}}>{row.sr_date}</span></td>
                      <td style={{fontSize: 13}}>{row.sr_id}</td>
                      <td style={{fontSize: 13}}>{row.tran_name}</td>
                      <td style={{fontSize: 13}}>{row.sr_code}</td>
                      <td style={{fontSize: 13}}>{row.sr_product_name}</td>
                      <td style={{fontSize: 13}}>{row.sr_color}</td>
                      <td style={{fontSize: 13}}>{row.sr_size}</td> 
                      <td style={{fontSize: 13}}>{row.sr_unit}</td>
                      <td style={{fontSize: 13}}>{row.sr_opening}</td>
                      <td style={{fontSize: 13}}>{row.sr_opening}</td>
                      <td style={{fontSize: 13}}>{row.sr_in}</td>
                      <td style={{fontSize: 13}}>{row.sr_out}</td>
                      <td style={{fontSize: 13}}>{row.sr_value}</td>
                  </tr>
                  ))}
              </tbody>
            </Table>
            </Modal.Body>
        </Modal>
        {age == true ? null :
          <div style={{marginTop: 20}}>
                <Button onClick={increment} style={{float: 'right'}} variant="primary">Next</Button>
                {input == 0 ? null :
                  <Button onClick={decrement} style={{marginRight: 40, float: 'right'}} className='stock-prev' variant="light">Previous</Button>
                }
            </div>
}
      </div>
    )
}
export default DigitalWarehouseReport;
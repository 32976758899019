import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import * as FaIcons from "react-icons/fa";
import * as AiIcons from "react-icons/ai";
import { SidebarData } from "./sidebarData";
import { POS } from "./pos";
import SubMenu from "./subMenu";
import './sidebar.css'
import { IconContext } from "react-icons/lib";
import { Container, Row, Col, Button, Form  } from "react-bootstrap";
import Rightsidebar from "./rightsidebar";


const Nav = styled.div`
	background: #38B0DE;
	height: 80px;
	display: flex;
	justify-content: flex-start;
	align-items: center;
	z-index: 999;
	position: fixed;
	width: 100%
`;

const SidebarWrap = styled.div`
	width: 100%;
`;

const Sidebar = (props) => {
	const [sidebar, setSidebar] = useState(true);
	const [username, setUsername] = useState("")
	const [usertype, setUsertype] = useState("")
	const [pos, setPos] = useState(false)

	

	useEffect(() => {
		var userdata = JSON.parse(localStorage.getItem("user"))
		var branch = JSON.parse(localStorage.getItem("branch"))
		var pos1 = JSON.parse(localStorage.getItem("pos"))
		setPos(pos1)
		setUsername(userdata.user_name)
		if(userdata.user_type == 1) {
			setUsertype("Sales Executive")
		}
		else if(userdata.user_type == 0){
			setUsertype("Admin")
		}
		else if(userdata.user_type == 2){
			setUsertype("Front end User")
		}
		else if(userdata.user_type == 3){
			setUsertype("Delivery Boy")
		}
		else if(userdata.user_type == 4){
			setUsertype("HR")
		}
		else if(userdata.user_type == 5){
			setUsertype("stockmanager")
		}
		else{
			setUsertype("Superadmin")
		}
	
		
	}, []);
	


	const showSidebar = (childData) => {
		props.getData(childData)
	};

	return (
		<>
		<IconContext.Provider value={{ color: "#fff" }}>
			<Nav>
				<div className="menu-icon" to="#">
					<FaIcons.FaBars  onClick={() =>showSidebar(!props.activeData)} />
				</div>
				<Rightsidebar />
			</Nav>
				<div className={"main-menu"}>
					<div className="logodiv">
						<img className="header-logo" src={require("../../assets/images/logo.svg").default} style={{width: '100%'}} />
					</div>
					<p style={{textAlign: 'center', color: '#fff', fontWeight: 'bold', fontSize: 20}}>ONLINE VIA INTERNET</p>
					<div className="avatardiv">
						<img className="avatarimage" src={require("../../assets/images/unknown.jpeg").default} style={{width: '100%'}} />
					</div>
					
						<p style={{textAlign: 'center', marginBottom: 'unset', color: '#fff', marginTop: 10, fontWeight: 'bold', fontSize: 22}}>{username}</p>
						<p style={{textAlign: 'center', color: '#fff', marginBottom: 'unset', fontSize: 15}}>{usertype}</p>
				
					<SidebarWrap className="menu-container">
						{
							pos == true ?
							POS.map((item, index) => {
								return <SubMenu item={item} key={index} parent = {() =>showSidebar(!props.activeData)}/>
							}) : 
							SidebarData.map((item, index) => {
								return <SubMenu item={item} key={index} parent = {() =>showSidebar(!props.activeData)}/>
							})
						}
					</SidebarWrap>
				</div>
		</IconContext.Provider>
		</>
	);
};

export default Sidebar;

import React, {useState, useEffect} from 'react';
import { Form  } from "react-bootstrap";
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import * as FaIcons from "react-icons/fa";
import * as RiIcons from "react-icons/ri";
import { Modal , Row, Col } from "react-bootstrap";
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Map from '../google-map/map';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import Loader from '../loader/loader'
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import { useHistory } from "react-router-dom";


const columns = [
    { id: '1', label: 'Sl No.', minWidth: 50,span: false },
    { id: '1', label: 'Ref Code', minWidth: 50,span: false },
    { id: '2', label: 'Product Name', minWidth: 50,span: false },
    { id: '3', label: 'Size', minWidth: 50,span: false },
    { id: '4', label: 'Colour', minWidth: 50,span: false },
    // { id: '5', label: 'Item Cost', minWidth: 50,span: false },
    { id: '5', label: 'Opening', minWidth: 50,span: false },
    { id: '5', label: 'In', minWidth: 50,span: false },
    { id: '8', label: 'Out', minWidth: 50,span: false },
    { id: '5', label: 'Closing', minWidth: 50,span: false },
    { id: '5', label: 'Item Cost', minWidth: 50,span: false },
    { id: '8', label: 'Stock Value', minWidth: 50,span: false },
    // { id: '9', label: 'Closing', minWidth: 50,span: false },
    // { id: '10', label: 'Stock Value', minWidth: 50,span: false },
  ];
  
  function createData(name, code, population, size) {
    const density = population / size;
    return { name, code, population, size, density };
  }

  
function Detailedstock(props) {
  const [usercompanyid, setUsercompanyid] = useState("")
  const [userbranchid, setUserbranchid] = useState("")
  const [usertype, setUsertype] = useState("")
  const [userId, setUserid] = useState("")

  const [toggle,  setToggle] = useState(false)
  const [modal, setModal] = useState(false);
  const [age, setAge] = React.useState('');
  const [input, setInput] = useState(0);
  const [filt, setFilt] = useState(false)
  const [source, setSource] = React.useState([]);
  const [tname, setTname] = React.useState([])

  const [loader, setLoader] = React.useState(false);
  const [routeId, setRouteid] = React.useState("");
  const [routename, setRoutename] = React.useState("");
  const [description, setDescription] = React.useState("");
  const [blocked, setBlocked] = React.useState(0);
  const [batchcode, setBatchcode] = React.useState("");
  const [dateFrom, setDatefrom] = React.useState(new Date)
  const [dateTo, setDateTo] = React.useState(new Date)

  function setData(childData) {
    setToggle(childData)
  }
  
  let history = useHistory();

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChange = (event) => {
    setAge(event.target.value);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const checkChange = (e) => {
    let isChecked = e.target.checked;
    if(isChecked == true) {
      setBlocked(1)
    }
    else {
      setBlocked(0)
    }
    // do whatever you want with isChecked value
  }

  useEffect(() => {
      getData()
    }, [input]);

    const getData = () => {
        var userdata = JSON.parse(localStorage.getItem("user"))
        var branch = JSON.parse(localStorage.getItem("branch"))
        var company = JSON.parse(localStorage.getItem("company"))
        setUsercompanyid(company)
        setUserbranchid(branch)
        setUsertype(userdata.user_type)
        setUserid(userdata.user_id)
        setLoader(true)
        var data = {
          "type":  userdata.user_type,
          "companyid": company,
          "branchid" : branch,
          "page" : input*10,
          "limit": 10,
          "from_date":  dateFrom.toISOString().split('T')[0],
          "to_date": dateTo.toISOString().split('T')[0],
          
        }
        fetch(global.url + "stockSummaryReport", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data)
          })
          .then((response)=> response.json())
          .then((responseJson)=>{
    
              console.log("response detailss stock", responseJson)
              setLoader(false)
              console.log("response details stock", responseJson)
              if(responseJson.error == false){
                
                setSource(responseJson.data)
              }
          })
          .catch((error)=>{
              console.log(error)
          })
    }
    const decrement = () => {
      setInput(prevCount => prevCount - 1);
      
    }

   const increment = () => {
      setInput(prevCount => prevCount + 1);
    
    }

    const filter = () => {
      setFilt(true)
        var userdata = JSON.parse(localStorage.getItem("user"))
        var branch = JSON.parse(localStorage.getItem("branch"))
        var company = JSON.parse(localStorage.getItem("company"))
        setUsercompanyid(company)
        setUserbranchid(branch)
        setUsertype(userdata.user_type)
        setUserid(userdata.user_id)
        setLoader(true)
        var data = {
          "type":  userdata.user_type,
          "companyid": company,
          "branchid" : branch,
          "refcode": batchcode,
          "from_date":  dateFrom.toISOString().split('T')[0],
          "to_date": dateTo.toISOString().split('T')[0],
        }
        fetch(global.url + "stockSummaryReport", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data)
          })
          .then((response)=> response.json())
          .then((responseJson)=>{
              console.log("response route", responseJson)
              setLoader(false)
              if(responseJson.error == false){
                
                setSource(responseJson.data)
              }
          })
          .catch((error)=>{
              console.log(error)
          })
    }

    const TabClick = (id, open) => {
     window.open('/detailsofstocks/' + id + '/' + open + '/' + dateFrom.toISOString().split('T')[0] + '/' +  dateTo.toISOString().split('T')[0],"_blank")
    }

    return(
        <div className="main-content">
          
        <Paper sx={{ width: '100%', overflow: 'hidden' }}>
            <div style={{margin: 20, flexDirection: 'row', justifyContent: 'space-between', display: 'flex'}}>
               <p style={{fontSize:20,  marginBottom: 0, fontWeight: 'bold'}}>Detailed Summary</p>
            </div>

            <Row style={{margin: 20}}>
                  <Col xs={12} md={3} style={{marginTop: 20}}>
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                          <DatePicker
                              label="From"
                              value={dateFrom}
                              onChange={(newValue) => {
                                  setDatefrom(newValue);
                              }}
                              renderInput={(params) => <TextField {...params} />}
                          />
                      </LocalizationProvider>
                  </Col>
                  <Col xs={12} md={3} style={{marginTop: 20}}>
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                          <DatePicker
                              label="To"
                              value={dateTo}
                              onChange={(newValue) => {
                                  setDateTo(newValue);
                              }}
                              renderInput={(params) => <TextField {...params} />}
                          />
                      </LocalizationProvider>
                  </Col>

                  <Col xs={12} md={3} style={{marginTop: 20}}>
                        <FormControl sx={{ width: '100%'}}>
                            <TextField name="Zone"
                            onChange={(e) => setBatchcode(e.target.value)}
                            autoComplete="off" id="outlined-basic" label="Ref code" variant="outlined"  size="small"/>
                        </FormControl>
                  </Col>
                  <Col xs={12} md={3} style={{marginTop: 20}}>
                    <Button onClick={filter}  style={{width: '50%'}} variant="contained">Filter</Button>
                  </Col>
            </Row>
            
            <TableContainer sx={{ maxHeight: 440, marginTop: 5 }}>
            {loader ? <Loader /> :
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    {columns.map((column) => (
                      <TableCell
                        key={column.id}
                        align={column.align}
                        style={{ minWidth: column.minWidth, color: '#777', fontWeight: 'bold', fontSize: 14 }}
                        colSpan={column.span == true ? 2: 1}
                      >
                        {column.label}
                        
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                
                <TableBody>
                  {
                  source

                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, index) => {
                      return (
                        <TableRow onClick={ () => TabClick(row.batchcode, row.opening)} style={{cursor: 'pointer'}} tabIndex={-1} key={row.code}>
                              <TableCell style={{fontWeight: 'bold', fontSize: 12}}>
                                  {index+1}
                              </TableCell>
                              <TableCell style={{fontWeight: 'bold', fontSize: 12}}>
                                  {row.product_refcode}
                              </TableCell>
                              <TableCell style={{fontWeight: 'bold', fontSize: 12}}>
                                  {row.product_name}
                              </TableCell>
                              <TableCell style={{fontWeight: 'bold', fontSize: 12}}>
                                  {row.size_name}
                              </TableCell>
                              <TableCell style={{fontWeight: 'bold', fontSize: 12}}>
                                  {row.color_name}
                              </TableCell>
                              {/* <TableCell style={{fontWeight: 'bold'}}>
                                  {row.sr_cost}
                              </TableCell> */}

                              <TableCell style={{fontWeight: 'bold', fontSize: 12}}>
                                  {row.opening}
                              </TableCell>
                              <TableCell style={{fontWeight: 'bold', fontSize: 12}}>
                                  {row.total_in}
                              </TableCell>
                              <TableCell style={{fontWeight: 'bold', fontSize: 12}}>
                                  {row.total_out}
                              </TableCell>
                              <TableCell style={{fontWeight: 'bold', fontSize: 12}}>
                                  {row.closing}
                              </TableCell>
                              <TableCell style={{fontWeight: 'bold', fontSize: 12}}>
                                  {row.itemcost}
                              </TableCell>
                              <TableCell style={{fontWeight: 'bold', fontSize: 12}}>
                                  {row.stockvalue}
                              </TableCell>
                              {/* <TableCell style={{fontWeight: 'bold'}}>
                                 
                              </TableCell> */}
                              {/* <TableCell style={{fontWeight: 'bold'}}>
                                {row.sr_value}
                              </TableCell> */}
                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>
  }
            </TableContainer>
            {/* <TablePagination
              rowsPerPageOptions={[10, 25, 100]}
              component="div"
              count={source.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            /> */}
          </Paper> 
          {/* <Modal
            size="xl"
            show={modal}
            onHide={() => setModal(false)}
            aria-labelledby="example-modal-sizes-title-lg"
            >
            <Modal.Header closeButton>
                <Modal.Title id="example-modal-sizes-title-lg">
                    Detailed Stock
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
            <Table responsive bordered>
              <thead>
                <tr>
                  <th>Voucher Date</th>
                  <th>Voucher No</th>
                  <th>Voucher Type</th>
                  <th>Code</th>
                  <th>Product Name</th>
                  <th>Colour</th>
                  <th>Size</th> 
                  <th>Unit</th>
                  <th>Opening</th>
                  <th>In</th>
                  <th>Out</th>
                  <th>Cost</th>
                  <th>Total</th>
                </tr>
              </thead>
              <tbody>
                  {tname.map((row, index) => (
                    <tr style={{marginTop: 20}}>
                      <td style={{fontSize: 13}}><span style={{fontSize: 13, marginTop: 20}}>{row.sr_date}</span></td>
                      <td style={{fontSize: 13}}>{row.sr_id}</td>
                      <td style={{fontSize: 13}}>{row.tran_name}</td>
                      <td style={{fontSize: 13}}>{row.sr_code}</td>
                      <td style={{fontSize: 13}}>{row.sr_product_name}</td>
                      <td style={{fontSize: 13}}>{row.sr_color}</td>
                      <td style={{fontSize: 13}}>{row.sr_size}</td> 
                      <td style={{fontSize: 13}}>{row.sr_unit}</td>
                      <td style={{fontSize: 13}}>{row.sr_opening}</td>
                      <td style={{fontSize: 13}}>{row.sr_opening}</td>
                      <td style={{fontSize: 13}}>{row.sr_in}</td>
                      <td style={{fontSize: 13}}>{row.sr_out}</td>
                      <td style={{fontSize: 13}}>{row.sr_value}</td>
                  </tr>
                  ))}
              </tbody>
            </Table>
            </Modal.Body>
        </Modal> */}
        {filt == true ? null :
        <div style={{marginTop: 20}}>
                <Button onClick={increment} style={{float: 'right', backgroundColor: '#1976d2', color: '#ffffff'}}>Next</Button>
                {input == 0 ? null :
                  <Button onClick={decrement} style={{marginRight: 40, float: 'right'}} className='stock-prev' variant="light">Previous</Button>
                }
            </div>
            }
      </div>
    )
}
export default Detailedstock;
import React, { useState, useEffect} from 'react';
import { Form  } from "react-bootstrap";
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import * as FaIcons from "react-icons/fa";
import * as RiIcons from "react-icons/ri";
import { Modal , Row, Col, Button } from "react-bootstrap";
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
// import Button from '@mui/material/Button';
import Map from '../google-map/map';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import Loader from '../loader/loader'
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import { useParams } from "react-router-dom";


const columns = [
  { id: '1', label: 'Voucher Date.', minWidth: 50,span: false },
  { id: '1', label: 'Voucher No', minWidth: 50,span: false },
  { id: '2', label: 'Voucher Type', minWidth: 50,span: false },
  { id: '3', label: 'Code', minWidth: 50,span: false },
  { id: '3', label: 'Product Name', minWidth: 50,span: false },
  { id: '4', label: 'Colour', minWidth: 50,span: false },
  { id: '3', label: 'Size', minWidth: 50,span: false },
  { id: '4', label: 'Unit', minWidth: 50,span: false },
  // { id: '5', label: 'Item Cost', minWidth: 50,span: false },
  // { id: '5', label: 'Opening', minWidth: 50,span: false },
  { id: '5', label: 'Inwards', minWidth: 50,span: false },
  { id: '8', label: 'Outwards', minWidth: 50,span: false },
  
  { id: '5', label: 'Closing', minWidth: 50,span: false }, 
  { id: '5', label: 'Cost', minWidth: 50,span: false },
  { id: '8', label: 'Total', minWidth: 50,span: false },
  // { id: '9', label: 'Closing', minWidth: 50,span: false },
  // { id: '10', label: 'Stock Value', minWidth: 50,span: false },
];


function Detailsofstocks() {
  const {id, open, fromdate, todate} = useParams()
  const [usercompanyid, setUsercompanyid] = useState("")
  const [userbranchid, setUserbranchid] = useState("")
  const [usertype, setUsertype] = useState("")
  const [userId, setUserid] = useState("")
  const [length, setLength] = useState()

  const [loader, setLoader] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [source, setSource] = useState([]);
  const [input, setInput] = useState(0);
  const [opening, setOpening] = useState();

  useEffect(() => {
    getData()
    setOpening(open)
  }, [input, opening]);

  const getData = () => {
    var userdata = JSON.parse(localStorage.getItem("user"))
    var branch = JSON.parse(localStorage.getItem("branch"))
    var company = JSON.parse(localStorage.getItem("company"))
    setUsercompanyid(company)
    setUserbranchid(branch)
    setUsertype(userdata.user_type)
    setUserid(userdata.user_id)
    var data = {
      "type":  userdata.user_type,
      "companyid": company,
      "batchcode": id,
      "from_date":  fromdate,
      "to_date": todate, 
      "limit" : 10, 
      "page" : input*10,
      "openigbalance": opening,
    }
    console.log("jasmin", data);
    fetch(global.url + "stockSummaryDetailReport", {
        method: "POST",
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data)
      })
      .then((response)=> response.json())
      .then((responseJson)=>{
        console.log("response det", responseJson)
          if(responseJson.error == false){
            setSource(responseJson.data) 
            setLength(responseJson.data.length-1)
          }
      })
      .catch((error)=>{
          console.log(error)
      })
  }

  const decrement = () => {
    setInput(prevCount => prevCount - 1);
  }

  const increment = () => {
      setInput(prevCount => prevCount + 1);
  }


  return (
    <div className="main-content">
      <Paper sx={{ width: '100%', overflow: 'hidden' }}>
        <Row style={{margin: 20}}>
          <div style={{fontSize:24, fontWeight:500, marginTop:13, marginLeft:15}}>Detailed Stock</div>
          
        <TableContainer sx={{ maxHeight: 440, marginTop: 2 }}>
            {loader ? <Loader /> :
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    {columns.map((column) => (
                      <TableCell
                        key={column.id}
                        align={column.align}
                        style={{ minWidth: column.minWidth, color: '#777', fontWeight: 'bold', fontSize: 16 }}
                        colSpan={column.span == true ? 2: 1}
                      >
                        {column.label}
                        
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                
                <TableBody>
                <TableRow>
                
                  <TableCell colSpan={8}>Opening</TableCell>
                  
                  <TableCell colSpan={5}>{opening}</TableCell>
                  
                 
                 </TableRow>
                {
                  source
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, index) => {
                      return (
                       
                        <TableRow >
                              <TableCell style={{fontWeight: 'bold'}}>
                                {row.sr_date}
                              </TableCell>
                              <TableCell style={{fontWeight: 'bold'}}>
                              {row.cb_voucher_no_fk}
                              </TableCell>
                              <TableCell style={{fontWeight: 'bold'}}>
                              {row.tran_name}
                              </TableCell>
                              <TableCell style={{fontWeight: 'bold'}}>
                              {row.sr_code}
                              </TableCell>
                              <TableCell style={{fontWeight: 'bold'}}>
                              {row.sr_product_name}
                              </TableCell>
                              <TableCell style={{fontWeight: 'bold'}}>
                              {row.sr_color}
                              </TableCell>
                              <TableCell style={{fontWeight: 'bold'}}>
                              {row.sr_size}
                              </TableCell>
                              <TableCell style={{fontWeight: 'bold'}}>
                              {row.sr_unit}
                              </TableCell>
                              {/* <TableCell style={{fontWeight: 'bold'}}>
                              {row.sr_opening}
                              </TableCell> */}
                              <TableCell style={{fontWeight: 'bold'}}>
                              {row.sr_in}
                              </TableCell>
                              <TableCell style={{fontWeight: 'bold'}}>
                              {row.sr_out}
                              </TableCell>
                              {length == index ?   
                              <TableCell style={{fontWeight: 'bold'}}>
                              {row.closing}
                              </TableCell>:
                              <TableCell style={{fontWeight: 'bold'}}>
                              
                              </TableCell>
                              }
                              {length == index ?
                              <TableCell style={{fontWeight: 'bold'}}>
                              {row.sr_cost}
                              </TableCell>:
                              <TableCell style={{fontWeight: 'bold'}}>
                             
                              </TableCell>
                              }
                              {length == index ?
                                <TableCell style={{fontWeight: 'bold'}}>
                                  {row.sr_value}
                                </TableCell>:
                                <TableCell style={{fontWeight: 'bold'}}>
                                  
                                </TableCell>
                              }
                              
                        </TableRow>
                     );
                    })}
                </TableBody>
              </Table>
            }
            </TableContainer>
              <div style={{marginTop: 20}}>
                  <Button onClick={increment} style={{float: 'right'}} variant="primary">Next</Button>
                  {input == 0 ? null :
                  <Button onClick={decrement} style={{marginRight: 40, float: 'right', borderColor: '#000'}} className='stock-prev' variant="light">Previous</Button>
                  }
              </div>
        </Row>
      </Paper>
    </div>
  )
}

export default Detailsofstocks